var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Vendors",
      headers: _vm.headers,
      data: _vm.VendorsList,
      loading: _vm.isLoading,
      "col-props": ["Status"],
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "column-Status",
        fn: function (props) {
          return [
            _c("pga-entity-status", {
              model: {
                value: props.row.item.Status,
                callback: function ($$v) {
                  _vm.$set(props.row.item, "Status", $$v)
                },
                expression: "props.row.item.Status",
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }