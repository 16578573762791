var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c("pga-authorized-only-one-content", {
            attrs: { permissions: ["DeleteVendor", "RequestDeletionVendor"] },
            scopedSlots: _vm._u([
              {
                key: "DeleteVendor",
                fn: function () {
                  return [
                    _c("pga-delete-button", {
                      on: { click: _vm.ShowDialogDelete },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "RequestDeletionVendor",
                fn: function () {
                  return [
                    _vm.isRequestableDeletion
                      ? _c("pga-delete-button", {
                          attrs: { text: "Request Deletion" },
                          on: { click: _vm.ShowDialogRequestDelete },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm.isApprovableDeletion
            ? _c(
                "pga-authorized-content",
                { attrs: { permission: "ApproveDeletionVendor" } },
                [
                  _c("pga-approve-button", {
                    attrs: { text: "Approve Deletion" },
                    on: { click: _vm.approveDeletion },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isApprovable
            ? _c(
                "pga-authorized-content",
                { attrs: { permission: "ApproveVendor" } },
                [_c("pga-approve-button", { on: { click: _vm.approve } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.VendorDetails } },
        [
          _c("pga-vendor-details", {
            model: {
              value: _vm.VendorDetails,
              callback: function ($$v) {
                _vm.VendorDetails = $$v
              },
              expression: "VendorDetails",
            },
          }),
          _c("pga-dialog", {
            attrs: {
              title: _vm.title,
              "icon-title": "mdi-delete-alert",
              message: _vm.message,
              color: "orange",
              "icon-button": "mdi-checkbox-marked-circle-outline",
            },
            on: { agree: _vm.Delete, reject: _vm.HideDialog },
            model: {
              value: _vm.showDialog,
              callback: function ($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }